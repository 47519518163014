export const USECASE = {
  LOGIN: "LoginUsecase",
  RESET_PASSWORD: "ResetPasswordUsecase",
  FORGOT_PASSWORD: "ForgotPasswordUsecase",
  GENERATE_OTP: "GenerateOTPUsecase",
  VERIFY_OTP: "VerifyOTPUsecase",
  COUNTRY_LIST: "CountryListUsecase",
  UPDATE_USER: "UpdateUserUsecase",
  ABOUT_USER: "AboutUserUsecase",
  CHANGE_TENANT_ESTATE: "ChangeTenantEstateUsecase",
  SEND_MESSAGE: "SendMessageUsecase",
  RETRY_ANSWER: "RetryAnswerUsecase",
  SESSION_LIST: "SessionListUsecase",
  TENANT_USERS_SESSION_LIST: "TenantUsersSessionListUsecase",
  FILE_UPLOAD: "FileUploadUsecase",

  STAFF: {
    STAFF_LIST: "StaffListUsecase",
    ADD_STAFF: "AddStaffUsecase",
    DELETE_STAFF: "DeleteStaffUsecase",
    INVITE_STAFF: "InviteStaffUsecase",
    ACCEPT_INVITE: "AcceptInviteUsecase",
  },
  PRODUCT: {
    PRODUCT_LIST: "ProductListUsecase",
    BUY_PRODUCT: "BuyProductUsecase",
    UPDATE_PRODUCT_MODEL_SETTING: "UpdateProductModelSettingUsecase",
    GET_PRODUCT: "GetProductUsecase",
    GET_PURCHASED_PRODUCT: "GetPurchasedProductUsecase",
  },
  STATISTIC: "StatisticUsecase",
  POLLSTER: {
    CREATE_POLL: "CreatePollUsecase",
    POLL_LIST: "PollListUsecase",
    STATISTIC: {
      POLL_LIST: "StatisticPollListUsecase",
      POLL_MESSAGE_LIST: "StatisticPollMessageListUsecase",
      POLL_RESPONSE_LIST: "StatisticPollResponseListUsecase",
      POLL_SESSION_DETAIL: "StatisticPollSessionDetailUsecase",
    },
    POLL_DETAIL: "PollDetailUsecase",
    DELETE_POLL: "DeletePollUsecase",
    UPDATE_POLL: "UpdatePollUsecase",
    INVITE_USER: "InviteUserUsecase",
  },
  INTERVIEW: {
    CREATE_INTERVIEW: "CreateJobPostingUsecase",
    CREATE_JOBPOSTING: "CreateJobPostingUsecase",
    JOBPOSTING_LIST: "JobPostingListUsecase",
    INTERVIEW_LIST: "InterviewListUsecase",
    JOBPOSTING_DETAIL: "JobPostingDetailUsecase",
    DELETE_JOBPOSTING: "DeleteJobPostingUsecase",
    DELETE_INTERVIEW: "DeleteInterviewUsecase",
    UPDATE_JOBPOSTING: "UpdateJobPostingUsecase",
    UPDATE_INTERVIEW: "UpdateInterviewUsecase",
    ADD_CANDIDATE: "AddCandidateUsecase",
    ADD_QUALIFICATION: "AddQualificationUsecase",
    REMOVE_QUALIFICATION: "RemoveQualificationUsecase",
  }
};
