export default {
  project: "Job Assistant",
  year: "2024",
  common: {
    pleaseWait: "Please wait...",
    process: "Process",
    loading: "Loading...",
    layout: {
      contact: "Contact Us",
      profile: "My Profile",
      language: "Language",
      signOut: "Sign Out",
    },
    button: {
      create: "Create",
      add: "Add",
      start: "Start",
      save: "Save",
      cancel: "Cancel",
      edit: "Edit",
      verify: "Verify",
      ok: "OK",
      continue: "Continue",
      send: "Send",
      install: "Install",
      delete: "Delete",
      yes: "Yes",
      no: "No",
      invite: "Invite",
      close: "Close",
      review: "Review",
      copy: "Copy",
      update: "Update",
    },
    table: {
      process: "Process",
      notProcess: "Cannot be processed as it is the default",
      pagination: {
        desc: " showing from ",
        desc1: " results.",
        desc2: "items per page",
      },
    },
    times: {
      minuteAgo: "minute ago",
      hoursAgo: "hour ago",
      dayAgo: "day ago",
      monthAgo: "month ago",
    },
    dontLiked: "Don't Liked",
    continues: "Continues",
    completed: "Completed",
    ended: "Ended",
    general: "General",
    special: "Special",
  },
  asideMenu: {
    modules: {
      header: "Modules",
      activeModules: "Active Modules",
      notFoundActiveModule: "No active module found.",
      hotelManagement: {
        title: "AI Hotel Assistant",
        assistant: "AI Assistant",
        history: "Conversation Archives",
        staffList: {
          title: "Staff List",
          desc: "On this page, you can view the staff registered to your company and review past AI assistant conversation records.",
        },
      },
      pollster: {
        title: "Pollster",
        polls: "Poll List",
        userAssignment: "Assign Poll",
        userPolls: "User Polls",
        myPolls: "My Polls",
        create: "Create Poll",
        emailAddresses: "Email Addresses",
      },
      interview: {
        title: "Interview",
        create: "Create Interview",
        createJobPosting: "Create Job Posting",
        historyList: "History List",
        list: "Interview List",
        jobPostingList: "Job Posting List",
        userAssignment: "Send Interview Invitation",
      },
    },
    admin: {
      header: "Admin",
      modules: "All Modules",
      accountTypes: "Account Types",
      accountStatus: "Account Status",
      staff: "Staff List",
      inviteStaff: "Add Staff",
    },
    ogza: {
      header: "Ogzatech Management",
      modules: {
        title: "Modules",
        allModules: "All Modules",
        createModul: "Create Module",
      },
      companies: "Registered Companies",
      createCompany: "Create Company",
      chatgptSettings: "ChatGpt Settings",
    },
    user: "User",
  },
  landingPage: {
    menu: {
      home: "Home",
      assistant: "Work Assistant",
      login: "Login",
    },
    headerBanner: {
      title: "Simplify Your Business Process with AI!",
      desc: "Elevate your business processes with AI! Meet our smart automation solutions that increase productivity, save time, and reduce costs. Lighten your workload, focus on important tasks, and multiply your success.",
    },
    container: {
      mainTitle: "AI Business Assistant",
      mainHeader: "AI-Powered Business Assistant",
      mainDesc:
        "Simplify your work significantly with our AI-powered assistant modules. Provide faster and more efficient service to your staff and customers quickly. These modules optimize your business processes, automate repetitive tasks, respond to customer requests instantly, and help you make strategic decisions with data analysis.",
      title: "AI Hotel Assistant",
      desc: "Evaluate customer reviews for your hotel with AI-supported sentiment analysis for a near-realistic assessment.",
      title2: "AI-Powered Pollster",
      desc2:
        "Conduct internal and external surveys efficiently and quickly with the help of AI. Determine and record the accuracy of responses with sentiment analysis.",
      title3: "Live Interview with AI",
      desc3:
        "Make your recruitment processes more efficient with AI. Conduct live interviews with candidates, analyze their performance, and identify the most suitable candidates. Thus, make faster and more accurate hiring decisions.",
    },
  },
  login: {
    title: "Login",
    desc: "Please enter your phone number to log in.",
    desc2: "Please enter your account information to log in.",
    email: "Email",
    enterEmail: "Please enter your email address...",
    password: "Password",
    enterPassword: "Please enter your password...",
    phone: "Phone Number",
    country: "Country",
    forgotPassword: "Forgot Password",
    button: "Login",
  },
  verifyOTP: {
    title: "SMS Verification",
    desc: "Please enter the verification code sent to your phone via SMS.",
    code: "Verification Code",
    enterCode: "Enter verification code...",
  },
  profileSet: {
    title: "Account Information",
    title2: "Create Profile",
    desc: "Please update your account information...",
    desc2: "Please create your account...",
    name: "Name",
    enterName: "Enter your name...",
    surname: "Surname",
    enterSurname: "Enter your surname...",
    email: "Email",
    enterEmail: "Enter your email address...",
    company: "Company",
    enterCompany: "Enter company name...",
    button: "Save and Login",
  },
  acceptInvite: {
    title: "Accept Invitation",
    desc: "Click the button below to accept the invitation.",
    button: "Accept",
  },
  companies: {
    createCompany: "Create New Company",
    createCompanyDesc:
      "Use AI modules in your own company and streamline your workflow.",
  },
  createCompany: {
    title: "Create Company",
    desc: "Please enter your company information...",
    companyName: "Company Name",
    enterCompanyName: "Enter company name...",
    companyDesc: "Company Description",
    enterCompanyDesc: "Describe your company...",
    sector: "Sector",
    enterSector: "Enter sector...",
  },
  dashboard: {
    title: "Dashboard",
    chart: {
      title: "Total Transaction Count",
      desc: "Graph of total transactions for the last 7 days",
    },
    sections: {
      totalProcessCount: "Total Record Count",
      modalCount: "Module Count",
      staffCount: "Staff Count",
      todaysprocessCount: "Today's Transaction Count",
    },
    lastProcess: {
      title: "Latest Records",
      type: "RECORD TYPE",
      nameSurname: "NAME SURNAME",
      email: "EMAIL",
      time: "COMPLETION TIME",
    },
  },
  profile: {
    title: "Profile",
    personelInfo: "Personal Information",
    name: "Name",
    enterName: "Enter your name",
    surname: "Surname",
    enterSurname: "Enter your surname",
    company: "Company",
    enterCompany: "Enter company name",
    phone: "Phone",
    enterPhone: "Enter your phone number...",
    email: "Email",
    enterEmail: "Enter your email address...",
  },
  modals: {
    detail: {
      pageTitle: "Module Details",
      current: "Current",
      modalDescription: "Module Description",
      packages: "Packages",
      numberOfUses: "Number of Uses",
      dayCount: "Number of Days",
      purchased: "Purchased",
      notPurchased: "Purchasable",
      free: "Free",
      FAQS: "Frequently Asked Questions",
      settings: "Module Settings",
      activeAiModel: "Active AI Model",
      details: {
        title: "Details",
        update: "Update",
        techSupport: "Technical Support",
        licence: "License",
        installation: "Installation",
        lifeLong: "Lifetime",
        full: "Full",
        oneClick: "One-Click",
      },
    },
    hotel: {
      title: "AI Hotel Assistant",
      search: "Search...",
      startNewSession: "Start New Chat",
      newSession: "New Chat",
      sessionCount: "Remaining Session Count",
      zeroSession: "No chat history found.",
      enterMessage: "Your message...",
      emptyMessage: "Please do not send empty messages.",
      staffChatHistory: "You are currently viewing the staff chat history.",
      staffHistories: "Staff Chat Histories",
      staffHistoriesDesc:
        "Please select the chat history you would like to review...",
      newChatDesc:
        "Welcome to the hotel assistant! Please send your topic of discussion or customer review related to your hotel. Our AI will provide realistic responses with its exceptional capabilities to ensure customer satisfaction.",
      closedSession:
        "This chat session has been completed. Please click on the 'Start New Chat' button on the left menu to begin a new chat.",
      staffList: {
        pageTitle: "Staff Members",
        title: "Staff List",
        desc: "You can view the staff registered to your company and review past AI assistant module chat records on this page.",
        inviteStaff: {
          title: "Add Staff",
          createStaff: "Create Staff Record",
          name: "Staff Name",
          enterName: "Enter name...",
          surname: "Staff Surname",
          enterSurname: "Enter surname...",
          email: "Staff Email",
          enterEmail: "Enter email address...",
          phone: "Staff Phone",
          enterPhone: "Enter phone number...",
          birthDate: "Birth Date",
          enterBirthDate: "Enter birth date...",
          department: "Department",
          enterDepartment: "Enter department...",
          position: "Position",
          enterPosition: "Enter position...",
        },
        name: "Name",
        surname: "Surname",
        phone: "Phone Number",
        email: "Email",
        department: "Department",
        mission: "Mission",
        sessionCount: "Chat Count",
        zeroCount: "No staff records found.",
      },
    },
    pollster: {
      title: "Pollster",
      poll: "Poll",
      createPollster: "Create Poll",
      pollDetails: "Poll Details",
      userAssignment: "User Poll Assignment",
      createLink: "Create Link",
      staffPoll: "Staff Poll",
      singlePollType: "Single Poll Type",
      singlePollTypeDesc:
        "Single polls are created to be sent as one-time use for each individual user.",
      addQuestion: "Add Question",
      openEnded: "Open-Ended",
      singleChoice: "Single Choice",
      multipleChoice: "Multiple Choice",
      responseOptions: "Response Options",
      updateEndDate: "Update End Date",
      updateEndDateDesc:
        "Please enter a new date to update the poll's end date.",
      addOption: "Add Option",
      startPoll: "Start Poll",
      emailPoll: "Registered Email Poll",
      name: "Name",
      enterName: "Enter name...",
      surname: "Surname",
      enterSurname: "Enter surname...",
      email: "Email",
      enterEmail: "Enter email address...",
      enterEmailDesc:
        "NOTE: To enter multiple email addresses, please separate them with a comma.",
      selectPoll: "Select Poll...",
      pollDesc: "Poll Description",
      link: "Poll Link",
      type: "Poll Type",
      staff: "Staff",
      sessionCode: "Session Code",
      enterSessionCode: "Enter Session Code...",
      pollsHistorys: "Polls History",
      polls: "Polls",
      pollsDesc:
        "You can view the polls you've predefined in the Pollster app on this page.",
      userPolls: {
        title: "User Polls",
        desc: "You can view the polls conducted by users in the Pollster app on this page.",
        poll: "Poll",
        status: "Status",
        duration: "Duration",
        pollType: "Poll Type",
        endDate: "End Date",
        detail: "Detail",
      },
      userPollDetail: {
        header: "User Poll Details",
        tabs: {
          one: "Poll Information",
          two: "User Responses",
          three: "Conversation Logs",
        },
      },
      isStaff: "STAFF?",
      nameSurname: "NAME SURNAME",
      date: "Completion Time",
      pollTitle: "Poll Title",
      enterTitle: "Enter title...",
      desc: "Description",
      enterDesc: "Enter description...",
      startDate: "Start Date",
      enterStartDate: "Select date...",
      endDate: "End Date",
      enterEndDate: "Select date...",
      duration: "Duration",
      percentComplete: "Percent Complete",
      status: "Status",
      questionCount: "Question Count",
      sessionCount: "Session Count",
      question: "Question",
      userAnswer: "User's Answer",
      enterQuestion: "Enter the question...",
      questionType: "Question Type",
      enterQuestionType: "Select question type...",
      emails: {
        title: "Email Addresses",
        desc: "You can manage the registered email addresses in the Pollster app on this page.",
        createNewEmail: "Add New Email",
        createEmailTitle: "Add Email",
        createEmailDesc:
          "Enter the email address you want to add to the module",
        email: "Email",
        enterEmail: "Enter email address...",
        pollCount: "Poll Count",
      },
      firstAiMessage:
        "Hello, I’m Pelin. Please answer the questions I’m going to ask honestly. All your feedback will be recorded and evaluated to improve our products.",
      pushToTalk: "Press to talk...",
      aiPollster: "AI Pollster",
      active: "Active",
      deActive: "Inactive",
      endConversation: "End Conversation",
      time: "Duration",
      zeroCount: "No polls found. Please add a new poll.",
    },
    interview: {
      title: "Interviewer",
      createJobPosting: "Create Job Posting",
      notFoundJobPosting: "No job postings found.",
      createJobPostingToolTip: "Create a job posting for the open position",
      jobPosting: "Job Posting",
      deleteJobPosting: "Delete Job Posting",
      editJobPosting: "Edit Job Posting",
      jobPostingDetails: "Job Posting Details",
      jobPostings: "Job Postings",
      allJobPostings: "All Job Postings",
      activeJobPostings: "Active Job Postings",
      inactiveJobPostings: "Inactive Job Postings",
      pendingJobPostings: "Pending Job Postings",
      candidateCount: "Candidate Count",
      morePieces: "more pieces..",
      inviteCandidate: "Invite Candidate",
      jobPostingTitle: "Job Posting Title",
      enterJobPostingTitle: "Enter title...",
      createInterview: "Create Interview",
      jobPostingDesc: "Job Posting Description",
      viewDetail: "View Details",
      qualifications: "Qualifications",
      forAddQualification: "Write and press Enter to add options",
      min3Qualification: "Please enter at least 3 qualifications.",
      name: "Name",
      enterName: "Enter name...",
      surname: "Surname",
      enterSurname: "Enter surname...",
      email: "Email",
      enterEmail: "Enter email address...",
      link: "Interview Link",
      interviewsHistorys: "Interview History",
      nameSurname: "NAME SURNAME",
      date: "Completion Time",
      status: "Status",
      questionCount: "Question Count",
      sessionCount: "Session Count",
      interviewTitle: "Interview Title",
      enterInterviewTitle: "Enter interview title...",
      position: "Position",
      enterPosition: "Enter position...",
      description: "Description",
      enterDescription: "Enter description...",
      interviewDetail: "Interview Details",
      startDate: "Start Date",
      enterStartDate: "Select date...",
      endDate: "End Date",
      enterEndDate: "Select date...",
      addQuestion: "Add Question",
      question: "Question",
      questionType: "Question Type",
      enterQuestion: "Enter the question...",
      enterQuestionType: "Select question type...",
      openEnded: "Open-Ended",
      singleChoice: "Single Choice",
      multipleChoice: "Multiple Choice",
      responseOptions: "Response Options",
      addOption: "Add Option",
      startInterview: {
        title: "Start Interview",
        desc: "Dear candidate, please read the following before starting the interview.",
        longDesc:
          "Dear candidate, thank you for applying to our job posting. Please check the interview details before starting and ensure the position you are applying for is correct before entering the interview.",
        name: "Name",
        enterName: "Enter your name...",
        surname: "Surname",
        enterSurname: "Enter your surname...",
        email: "Email",
        enterEmail: "Enter your email...",
      },
      addCandidate: {
        title: "Create Interview Invitation",
        interview: "Interview",
        selectInterview: "Select an interview...",
        name: "Name",
        enterName: "Enter name...",
        surname: "Surname",
        enterSurname: "Enter surname...",
        email: "Email",
        enterEmail: "Enter email...",
      },
      userInterviewDetail: {
        header: "User Interview Details",
        tabs: {
          one: "Interview Information",
          two: "User Responses",
          three: "Conversation Logs",
        },
      },
      firstAiMessage:
        "Hello, I am the AI interviewer. I’m here to get to know you. Please feel comfortable and answer my questions naturally.",
      pushToTalk: "Hold to talk...",
      percentComplete: "Interview Completion Percentage: ",
      aiInterviewer: "AI Interviewer",
      active: "Active",
      deActive: "Inactive",
      endConversation: "End Conversation",
      time: "Time",
      zeroCount: "No interviews found. Please add a new interview.",
    },
  },
  modalList: {
    title: "Module List",
    allModules: "All",
    myPurchases: "My Purchases",
    freeOnes: "Free Ones",
    availableForPurchase: "Available for Purchase",
    notFound: "Module not found.",
  },
  ogza: {
    staffList: {
      pageTitle: "Staff",
      title: "Staff List",
      desc: "You can view the staff registered in your company and review their past records on this page.",
      inviteStaff: {
        title: "Add Staff",
        createStaff: "Create Staff Record",
        name: "Staff Name",
        enterName: "Enter name...",
        surname: "Staff Surname",
        enterSurname: "Enter surname...",
        email: "Staff Email",
        enterEmail: "Enter email address...",
        phone: "Staff Phone",
        enterPhone: "Enter phone number...",
        birthDate: "Birth Date",
        enterBirthDate: "Enter birth date...",
        department: "Department",
        enterDepartment: "Enter department...",
        position: "Position",
        enterPosition: "Enter position...",
      },
      name: "Name",
      surname: "Surname",
      email: "Email",
      phone: "Phone",
      department: "Department",
      mission: "Position",
      status: "Status",
      zeroCount: "No staff records found.",
    },
    chatGptSettings: {
      title: "ChatGPT Settings",
      activeVersion: "Active ChatGPT Version",
      selectVersion: "Select Version...",
      apiKey: "API Key",
      enterApiKey: "Enter API Key...",
    },
    createModal: {
      title: "Create Module",
      modalIcon: "Module Icon",
      modalIconDesc: "Allowed file types: png, jpg, jpeg..",
      modalName: "Module Name",
      enterModalName: "Enter module name...",
      modalDesc: "Module Description",
      enterModalDesc: "Enter module description...",
      price: "Price",
      enterPrice: "Enter price...",
      detailedDesc: "Detailed Description",
      enterDetailedDesc: "Enter description...",
      question1: "Question 1",
      question2: "Question 2",
      enterQuestion: "Enter question...",
      answer: "Answer",
      enterAnswer: "Enter answer...",
      adminPropmt: "Module Admin Prompt",
      enterPrompt: "Enter prompt...",
      userSessionLimit: "User Session Limit",
      enterLimit: "Enter limit...",
      details: "Details",
      speakOutLoud: "Speak Out Loud",
      videoCall: "Video Call",
      guestUser: "Can guest users access?",
    },
    createCompany: {
      title: "Create Company",
      companyName: "Company Name",
      enterCompanyName: "Enter company name...",
      companyCategory: "Business Category",
      enterCompanyCategory: "Enter category...",
      managerName: "Manager's Name",
      enterManagerName: "Enter manager's name...",
      managerSurname: "Manager's Surname",
      enterManagerSurname: "Enter manager's surname...",
      email: "Email Address",
      enterEmail: "Enter email address...",
      country: "Country",
      phone: "Phone Number",
      enterPhone: "Enter phone number...",
      department: "Department",
      enterDepartment: "Enter department...",
      accountType: "Account Type",
      selectAccountType: "Select account type...",
    },
  },
  accountStatus: {
    pageTitle: "Account Status",
    title: "Account Information",
    companyName: "Company Name",
    enterCompanyName: "Enter company name...",
    email: "Email",
    enterEmail: "Enter email address...",
    accountType: "Account Type",
    activeModalCount: "Active Module Count",
    processCountofPeriod: "Transaction Count of Period",
    purchaseHistory: "Purchase History",
    receivedModule: "Received Module",
    price: "Price",
    procesDate: "Transaction Date",
  },
  accountTypes: {
    title: "Account Types",
    basic: "Basic Account",
    yourActiveAccount: "Your Active Account",
  },
  error404: {
    nothing: "Looks like there's nothing here",
    return: "Return to Homepage",
  },
  error500: {
    title: "System Error",
    desc: "You are not authorized to access this page!",
    return: "Return to Homepage",
  },
  swalMessages: {
    SUCCESSFUL: "Operation Successful!",
    FAILED: "Operation Failed!",
    SESSION_TIME_OUT:
      "Session timed out. Please log in again with your user credentials.",

    COPY_JOBPOSTING: "Do You Want to Copy This Job Posting?",
    COPY_JOBPOSTING_DESC:
      "Do you want to copy this job posting and create a new one?",
    DELETE_JOBPOSTING: "Are You Sure You Want to Delete the Job Posting?",
    CANNOT_BE_UNDONE: "This action cannot be undone.",

    CONFIRM_BUTTON_TEXT: "OK",
    API_ERROR_MESSAGE:
      "Technical error. Unable to connect to the server, please try again.",
    ERROR_CODE: " Error code: ",
    UNKNOWN_ERROR_MESSAGE: "An unknown error occurred.",
    ARE_YOU_SURE: "Are you sure?",
    SURE_DELETE_STAFF: "Are you sure you want to delete this staff member?",
    SURE_DELETE_POLL: "Are you sure you want to delete this poll?",
    SURE_DELETE_INTERVIEW: "Are you sure you want to delete this interview?",

    ERROR_GENERATE: "Verification code generation failed! Please try again.",
    SUCCESS_GENERATE: "Verification code sent successfully!",

    ERROR_VERIFY: "Verification failed! Please try again.",
    SUCCESS_VERIFY: "Verification successful!",
    ERROR_VERIFY_REMAINING_ATTEMPTS:
      "You've exceeded your attempt limit. Please try again later.",
    TIME_ERROR: "Time has expired. Please log in again.",

    ERROR_UPDATE_USER:
      "User information could not be updated. Please try again.",
    SUCCESS_UPDATE_USER: "User information updated successfully!",
    ERROR_CHANGE_TENANT_USER: "Technical error! Failed to switch tenant.",
    ERROR_ABOUT_USER: "Technical error! Failed to load personal information.",

    ERROR_ADD_STAFF: "Technical error! Failed to register staff.",
    ERROR_DELETE_STAFF: "Technical error! Failed to delete staff.",
    SUCCESS_DELETE_STAFF: "Staff member deleted successfully!",
    ERROR_STAFF_LIST: "Technical error! Failed to retrieve staff list.",
    ERROR_INVITE_STAFF: "Technical error! Failed to invite staff.",
    ERROR_POLL_LIST: "Technical error! Failed to retrieve poll list.",
    SUCCESS_INVITE_STAFF: "Staff invited successfully!",
    SUCCESS_ACCEPT_INVITE: "Access granted successfully.",
    ERROR_ACCEPT_INVITE:
      "Invalid invitation link, please contact your administrator.",

    ERROR_PRODUCT_LIST: "Technical error! Failed to retrieve module list.",
    SUCCESS_BUY_PRODUCT:
      "Congratulations, you successfully purchased the module!",
    ERROR_BUY_PRODUCT:
      "Transaction failed! Could not purchase the module. Please contact the administrator.",
    SUCCESS_UPDATE_PRODUCT_MODEL_SETTING: "Module model updated successfully.",
    ERROR_UPDATE_PRODUCT_MODEL_SETTING:
      "Transaction failed! Could not update module model.",

    ERROR_DELETE_POLL: "Technical error! Failed to delete poll.",
    SUCCESS_DELETE_POLL: "Poll deleted successfully!",
    ERROR_CREATE_POLL: "Technical error! Failed to create poll.",
    SUCCESS_CREATE_POLL: "Poll created successfully!",
    ERROR_UPDATE_POLL: "Technical error! Failed to update poll.",
    SUCCESS_UPDATE_POLL: "Poll updated successfully!",
    ERROR_INVITE_USER: "Technical error! Failed to invite users to the poll.",
    SUCCESS_INVITE_USER: "Poll invitation link sent successfully!",
    SUCCESS_INVITE_USERS: "Poll invitation links sent successfully!",
    ERROR_FILE_UPLOAD: "Technical error! Failed to upload file.",
    SUCCESS_FILE_UPLOAD: "File uploaded successfully!",
    ERROR_POLL_DETAIL: "Technical error! Failed to display poll details.",
    ERROR_POLL_STATISTICS_LIST: "Technical error! Failed to list past polls.",
    ERROR_POLL_STATISTICS_MESSAGE_LIST:
      "Technical error! Failed to display poll log messages.",
    ERROR_POLL_STATISTICS_SESSION_DETAIL:
      "Technical error! Failed to display poll details and statistics.",
    ERROR_POLL_STATISTICS_RESPONSE_LIST:
      "Technical error! Failed to display poll responses.",

    ERROR_INTERVIEW_LIST: "Technical error! Failed to list interviews.",
    ERROR_INTERVIEW_DETAIL:
      "Technical error! Failed to display interview details.",
    ERROR_DELETE_INTERVIEW: "Technical error! Failed to delete interview.",
    SUCCESS_DELETE_INTERVIEW: "Interview successfully deleted!",
    ERROR_CREATE_INTERVIEW: "Technical error! Failed to create interview.",
    ERROR_CREATE_JOBPOSTING: "Technical error! Failed to create jobposting.",
    SUCCESS_CREATE_INTERVIEW: "Interview successfully created!",
    SUCCESS_CREATE_JOBPOSTING: "Job posting successfully created!",
    ERROR_DELETE_JOBPOSTING: "Technical error! Failed to delete jobposting.",
    SUCCESS_DELETE_JOBPOSTING: "Job posting successfully deleted!",
    SUCCESS_COPY_JOBPOSTING: "Job posting successfully copied!",
    ERROR_UPDATE_INTERVIEW: "Technical error! Failed to update interview.",
    SUCCESS_UPDATE_INTERVIEW: "Interview successfully updated!",
    ERROR_UPDATE_JOBPOSTING: "Technical error! Failed to update jobposting.",
    SUCCESS_UPDATE_JOBPOSTING: "Job posting successfully updated!",
    ERROR_ADD_CANDIDATE:
      "Technical error! Failed to invite candidate to the interview.",
    SUCCESS_ADD_CANDIDATE: "Candidate successfully invited to the interview.",
    ERROR_REMOVE_QUALIFICATION:
      "Technical error! Failed to delete qualification.",
    SUCCESS_REMOVE_QUALIFICATION: "Qualification successfully deleted!",
    ERROR_ADD_QUALIFICATION: "Technical error! Failed to add qualification.",
    SUCCESS_ADD_QUALIFICATION: "Qualification successfully added!",

    ERROR_SEND_MESSAGE:
      "You cannot start a new session because your limit has been reached. Please upgrade your account.",
    SUCCESS_SEND_MESSAGE: "Congratulations, the message was sent successfully.",
    ERROR_GET_SESSION_LIST:
      "Technical error, failed to retrieve conversation history information!",
    SUCCESS_RETRY_ANSWER: "Congratulations, the answer was regenerated.",
    ERROR_RETRY_ANSWER:
      "You cannot generate an answer because your limit has been reached. Please upgrade your account.",

    SESSION_CLOSED:
      "The chat has been completed. To start a new chat, please click the 'Start New Conversation' button in the left menu.",

    ERROR_GET_STATISTICS_LIST: "Technical error! Failed to load statistics.",
  },
  customErrorMessages: {
    useCase: {
      error: "Usecase error.",
    },
    mapper: {
      error: "Mapper error.",
    },
    controller: {
      error: "Controller error.",
    },
    repository: {
      error: "Repository error.",
    },
    aggregateRoot: {
      error: "Aggregate root error.",
    },
    domainEvent: {
      error: "Domain Event error.",
    },
    email: {
      required: "Please enter your email address.",
      invalid: "Please enter a valid email address.",
    },
    password: {
      required: "Please enter your password.",
      min: "Password must be at least 6 characters long.",
      match: "Passwords must match.",
    },
    identity: {
      required: "Please enter your identity number.",
      length: "Identity number must consist of 11 digits.",
    },
    credential: {
      required: "Please enter your email address and password.",
      credential: "Invalid email address or password.",
    },
  },
  validators_error: {
    title: "Error!",
    required: "Please do not leave it blank.",
    typeError: "Please enter data of the appropriate type.",
    email: "Please enter a valid email address.",
    sameEmails: "You have already entered this email address.",
    minlength: "Cannot be less than ",
    maxlength: "Cannot be more than ",
    zeroValue: "The value cannot be less than 0.",
    phoneNumberFull: "Please enter your full phone number.",
    requiredCode: "Please do not leave the verification code blank.",
    requiredDesc: "Please do not leave the description blank.",
    requiredMin3Features: "Please enter at least 3 features.",
    codeInvalid: "Please enter a 6-digit verification code.",
    min18age: "Cannot be under 18 years of age.",
    startDateBiggerEndDate: "End date cannot be earlier than the start date.",
  },
};
