export const API_ROUTE = {
  ABOUT_USER: "users/me",
  CHANGE_TENANT_ESTATE: "auth/change-tenant-estate",
  GENERATE_OTP: "auth/generate-otp",
  VERIFY_OTP: "auth/verify-otp",
  COUNTRY_LIST: "country-codes",
  USER_DETAILS: "user-details",
  HOTEL_REVIEW_MODULE: "/hotel-reviews/",
  RETRY: "/retry",
  STAFF: "tenant-users/",
  PRODUCTS: "/products/",
  BUY_PRODUCT_PACKAGE: "/product-packages/buy",
  PRODUCT_PACKAGE: "/product-packages/",
  PURCHASED_PACKAGE: "/purchased-package/",
  STATISTIC: "/statistics/",
  INVITE: "invite",
  ACCEPT: "/accept",
  SURVEYS: "/surveys/",
  SURVEYS_SESSIONS: "/survey-sessions/",
  MESSAGES: "/messages/",
  META_DATA: "/meta-data/",
  RESPONSES: "/responses/",
  INVITE_USER: "/invite-surveys",
  FILE_UPLOAD: "/product-session-messages/upload",
  JOB_POSTINGS: "/job-postings/",
  CANDIDATE: "/candidates/",
  QUALIFICATIONS: "/qualifications/",
};
