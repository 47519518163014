import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { JobPostingListUseCase } from "@/application/interviewer/job-posting-list/usecase/JobPostingListUseCase";
import { JobPostingListModel } from "@/domain/interviewer/job-posting-list/model/JobPostingListModel";
import { DeleteJobPostingModel } from "@/domain/interviewer/delete-job-posting/model/DeleteJobPostingModel";
import { DeleteJobPostingUseCase } from "@/application/interviewer/delete-job-posting/usecase/DeleteJobPostingUseCase";
import { CreateJobPostingModel } from "@/domain/interviewer/create-job-posting/model/CreateJobPostingModel";
import { CreateJobPostingUsecase } from "@/application/interviewer/create-job-posting/usecase/CreateJobPostingUseCase";
import { UpdateJobPostingModel } from "@/domain/interviewer/update-job-posting/model/UpdateJobPostingModel";
import { UpdateJobPostingUseCase } from "@/application/interviewer/update-job-posting/usecase/UpdateJobPostingUseCase";
import { RemoveQualificationUseCase } from "@/application/interviewer/qualification/remove-qualification/usecase/RemoveQualificationUseCase";
import { RemoveQualificationModel } from "@/domain/interviewer/qualification/remove-qualification/model/RemoveQualificationModel";
import { AddQualificationModel } from "@/domain/interviewer/qualification/add-qualification/model/AddQualificationModel";
import { AddQualificationUseCase } from "@/application/interviewer/qualification/add-qualification/usecase/AddQualificationUseCase";

export function createInterviewerController() {
  const jobPostingListUseCase = new JobPostingListUseCase();
  const deleteJobPostingUseCase = new DeleteJobPostingUseCase();
  const createJobPostingUseCase = new CreateJobPostingUsecase();
  const updateJobPostingUseCase = new UpdateJobPostingUseCase();
  const removeQualificationUseCase = new RemoveQualificationUseCase();
  const addQualificationUseCase = new AddQualificationUseCase();

  return new InterviewerController(
    jobPostingListUseCase,
    deleteJobPostingUseCase,
    createJobPostingUseCase,
    updateJobPostingUseCase,
    removeQualificationUseCase,
    addQualificationUseCase
  );
}

export class InterviewerController implements IController {
  constructor(
    private jobPostingListUseCase: JobPostingListUseCase,
    private deleteJobPostingUseCase: DeleteJobPostingUseCase,
    private createJobPostingUseCase: CreateJobPostingUsecase,
    private updateJobPostingUseCase: UpdateJobPostingUseCase,
    private removeQualificationUseCase: RemoveQualificationUseCase,
    private addQualificationUseCase: AddQualificationUseCase
  ) {}

  async createJobPosting(
    createJobPostingModel: CreateJobPostingModel
  ): Promise<Result<CreateJobPostingModel>> {
    const response = await this.createJobPostingUseCase.execute(
      createJobPostingModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<CreateJobPostingModel>(response.getError());
    }
  }

  async updateJobPosting(
    updateJobPostingModel: UpdateJobPostingModel
  ): Promise<Result<CreateJobPostingModel>> {
    const response = await this.updateJobPostingUseCase.execute(
      updateJobPostingModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<CreateJobPostingModel>(response.getError());
    }
  }

  async jobPostingList(
    jobPostinglistModel: JobPostingListModel
  ): Promise<Result<JobPostingListModel>> {
    const response = await this.jobPostingListUseCase.execute(
      jobPostinglistModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<JobPostingListModel>(response.getError());
    }
  }

  async deleteJobPosting(
    deleteJobPostingModel: DeleteJobPostingModel
  ): Promise<Result<DeleteJobPostingModel>> {
    const response = await this.deleteJobPostingUseCase.execute(
      deleteJobPostingModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<DeleteJobPostingModel>(response.getError());
    }
  }

  async removeQualification(
    removeQualificationModel: RemoveQualificationModel
  ): Promise<Result<RemoveQualificationModel>> {
    const response = await this.removeQualificationUseCase.execute(
      removeQualificationModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<RemoveQualificationModel>(response.getError());
    }
  }

  async addQualification(
    addQualificationModel: AddQualificationModel
  ): Promise<Result<AddQualificationModel>> {
    const response = await this.addQualificationUseCase.execute(
      addQualificationModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AddQualificationModel>(response.getError());
    }
  }
}
