import { JobPostingListRepository } from "../repository/JobPostingListRepository";
import { Result } from "@/core/utility/ResultType";
import { JobPostingListMapper } from "../mapper/JobPostingListMapper";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IJobPostingListUseCase } from "@/domain/interviewer/job-posting-list/usecase/IJobPostingListUsecase";
import { IJobPostingListRepository } from "@/domain/interviewer/job-posting-list/repository/IJobPostingListRepository";
import { JobPostingListModel } from "@/domain/interviewer/job-posting-list/model/JobPostingListModel";

export class JobPostingListUseCase implements IJobPostingListUseCase {
  jobPostingListRepository : IJobPostingListRepository = new JobPostingListRepository();
  jobPostingListMapper = new JobPostingListMapper();

  constructor() {
  }

  async execute(model: JobPostingListModel): Promise<Result<JobPostingListModel>> {
    try {
      const dto = this.jobPostingListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<JobPostingListModel>(dto.getError());
      } else {
        const response = await this.jobPostingListRepository.jobPostingList(dto);

        if (response.isSuccess) {
          const domain = this.jobPostingListMapper.mapToDomain(response.getValue());
          return Result.ok<JobPostingListModel>(domain.getValue());
        } else {
          return Result.fail<JobPostingListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<JobPostingListModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.INTERVIEW_LIST))
      );
    }
  }
}
