import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { AddQualificationModel } from "@/domain/interviewer/qualification/add-qualification/model/AddQualificationModel";
import { AddQualificationRequestDto } from "@/domain/interviewer/qualification/add-qualification/dto/AddQualificationRequestDto";

export class AddQualificationMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<AddQualificationModel> {
    try {
        const updateInterviewModel: AddQualificationModel = {
          ok: dto.ok,
        };
        return Result.ok(updateInterviewModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.ADD_QUALIFICATION))
      );
    }
  }

  mapToDTO(domain: AddQualificationModel): Result<AddQualificationRequestDto> {
    try {
      const addQualificationRequest: AddQualificationRequestDto = {
        id: domain.id,
        data: {
          text: domain.text,
        }
      };

      return Result.ok(addQualificationRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.ADD_QUALIFICATION))
      );
    }
  }
}
