
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { AddQualificationRepository } from "../repository/AddQualificationRepository";
import { AddQualificationMapper } from "../mapper/AddQualificationMapper";
import { IAddQualificationUseCase } from "@/domain/interviewer/qualification/add-qualification/usecase/IAddQualificationUseCase";
import { AddQualificationModel } from "@/domain/interviewer/qualification/add-qualification/model/AddQualificationModel";

export class AddQualificationUseCase implements IAddQualificationUseCase {
  updateJobPostingRepository: AddQualificationRepository = new AddQualificationRepository();
  updateJobPostingMapper = new AddQualificationMapper();

  constructor() {}

  async execute(model: AddQualificationModel): Promise<Result<AddQualificationModel>> {
    try {
      const dto = this.updateJobPostingMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AddQualificationModel>(dto.getError());
      } else {
        const response = await this.updateJobPostingRepository.addQualification(dto);

        if (response.isSuccess) {
          const domain = this.updateJobPostingMapper.mapToDomain(response.getValue());

          return Result.ok<AddQualificationModel>(domain.getValue());
        } else {
          return Result.fail<AddQualificationModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AddQualificationModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.ADD_QUALIFICATION))
      );
    }
  }
}
