import { createOTPController } from "@/presentation/controllers/OTPController";
import { createCountryController } from "@/presentation/controllers/CountryController";
import { createUserController } from "@/presentation/controllers/UserController";
import { createSessionController } from "@/presentation/controllers/SessionController";
import { createStaffController } from "@/presentation/controllers/StaffController";
import { createProductController } from "@/presentation/controllers/ProductController";
import { createStatisticController } from "@/presentation/controllers/StatisticController";
import { createPollController } from "@/presentation/controllers/PollsterController";
import { createFileController } from "@/presentation/controllers/FileController";
import { createInterviewerController } from "@/presentation/controllers/InterviewerController";

export default {
  state: {
    otpController: null,
    countryController: null,
    userController: null,
    sessionController: null,
    staffController: null,
    productController: null,
    statisticController: null,
    pollsterController: null,
    interviewController: null,
    interviewerController: null,
    fileController: null,
  },
  mutations: {
    setController(state, { name, controller }) {
      state[name] = controller;
    },
  },
  actions: {
    initializeControllers({ commit }) {
      const controllers = [
        { name: "countryController", controller: createCountryController() },
        { name: "otpController", controller: createOTPController() },
        { name: "userController", controller: createUserController() },
        { name: "sessionController", controller: createSessionController() },
        { name: "staffController", controller: createStaffController() },
        { name: "productController", controller: createProductController() },
        { name: "statisticController", controller: createStatisticController() },
        { name: "pollsterController", controller: createPollController() },
        { name: "interviewerController", controller: createInterviewerController() },
        { name: "fileController", controller: createFileController() },
      ];

      controllers.forEach(({ name, controller }) => {
        commit("setController", { name, controller });
      });
    },
  },
};
