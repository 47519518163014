import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { UpdateJobPostingModel } from "@/domain/interviewer/update-job-posting/model/UpdateJobPostingModel";
import { UpdateJobPostingRequestDto } from "@/domain/interviewer/update-job-posting/dto/UpdateJobPostingRequestDto";

export class UpdateJobPostingMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<UpdateJobPostingModel> {
    try {
        const updateInterviewModel: UpdateJobPostingModel = {
          ok: dto.ok,
          // jobPostingQualifications: null,
          // interviews: [],
        };
        return Result.ok(updateInterviewModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.UPDATE_JOBPOSTING))
      );
    }
  }

  mapToDTO(domain: UpdateJobPostingModel): Result<UpdateJobPostingRequestDto> {
    try {
      const updateJobPosting: UpdateJobPostingRequestDto = {
        id: domain.id,
        data: {
          title: domain.title,
          description: domain.description,
          startDate: domain.startDate,
          endDate: domain.endDate,
          // jobPostingQualifications: domain.jobPostingQualifications,
          // interviews: domain.interviews
        }
      };

      return Result.ok(updateJobPosting);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.UPDATE_JOBPOSTING))
      );
    }
  }
}
