import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { RemoveQualificationModel } from "@/domain/interviewer/qualification/remove-qualification/model/RemoveQualificationModel";
import { RemoveQualificationRequestDto } from "@/domain/interviewer/qualification/remove-qualification/dto/RemoveQualificationRequestDto";

export class RemoveQualificationMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<RemoveQualificationModel> {
    try {
        const deleteInterviewModel: RemoveQualificationModel = {
          ok: dto.ok,
        };
        return Result.ok(deleteInterviewModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.REMOVE_QUALIFICATION))
      );
    }
  }

  mapToDTO(domain: RemoveQualificationModel): Result<RemoveQualificationRequestDto> {
    try {
      const deleteInterviewRequest: RemoveQualificationRequestDto = {
        jobPostingId: domain.jobPostingId,
        qualificationId: domain.qualificationId
      };

      return Result.ok(deleteInterviewRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.REMOVE_QUALIFICATION))
      );
    }
  }
}
